import { createStore } from 'vuex'
import IncomesService from '../services/IncomesService'
import IncomesTypesService from '../services/IncomesTypesService'

const _IncomesService = new IncomesService()
const _IncomesTypesService = new IncomesTypesService()

const storeIncomes = {
  state: {
    incomes: {
      count: 0,
      rows: []
    },
    typesIncomesObject: [],
    paginator: {
      rows: 8,
      first: 0
    }
  },
  mutations: {
    setIncomes (state, payload) {
      state.incomes = {
        ...payload
      }
    },
    setTypesIncomesObject (state, payload) {
      state.typesIncomesObject = payload
    },
    setPaginator (state, payload) {
      state.paginator = {
        ...payload
      }
    }
  },
  actions: {
    searchIncomes ({ commit }, payload) {
      _IncomesService.getIncomes(payload).then(({ data }) => {
        commit('setIncomes', data)
      }).catch(error => {
        console.log(error)
      })
    },
    searchTypesIncomes ({ commit }) {
      _IncomesTypesService.getIncomesTypes().then(({ data }) => {
        data.unshift({
          id: null,
          name: 'Seleccione un tipo'
        })
        commit('setTypesIncomesObject', data)
      }).catch(error => {
        console.log(error)
      })
    },
    gettingPaginator ({ commit }, payload) {
      commit('setPaginator', payload)
    },
    saveIncome ({ commit }, payload) {
      _IncomesService.saveIncome(payload).then(({ data }) => {
      }).catch(error => {
        console.log(error)
      })
    },
    deleteIncome ({ commit }, id) {
      _IncomesService.deleteIncome(id).catch(error => {
        console.log(error)
      })
    }
  },
  getters: {
    getIncomes: state => state.incomes,
    getTypesIncomes: state => state.typesIncomesObject,
    getPaginator: state => state.paginator
  }
}
const store = createStore(storeIncomes)

export default {
  namespaced: true,
  ...store
}
