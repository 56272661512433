<template>
  <div class="grid grid-cols-1 lg:grid-cols-6 gap-2 place-content-start">
    <div class="flex flex-col">
      <span class="font-bold">Fecha:</span>
<!--      <Dropdown v-model="incomeTypeSelected" :options="incomesTypes" optionLabel="name" optionValue="id"-->
<!--                placeholder="Seleccione un tipo" @change="onChangeIncomesTypes"/>-->
      <Calendar id="basic" v-model="dateSelected" autocomplete="off" @date-select="onChangeDate" />
    </div>
    <!-- <div class="flex flex-col">
      <span class="font-bold">N° Documento:</span>
      <InputText type="text" placeholder="Digite el N° Documento" v-model="numberDoc" @keyup="onChangeNumberDoc"/>
    </div> -->
    <div class="flex items-end justify-center">
      <Button label="Limpiar filtro"
              class="mr-2 p-button-info"
              @click="clearFilter"
      />
    </div>
    <div class="flex flex-col justify-end">
      <span class="font-bold">Total Ingresos:</span>
      <span>{{ $h.formatCurrency(storeTotalInc) }}</span>
    </div>
    <div class="flex flex-col justify-end">
      <span class="font-bold">Total Registros:</span>
      <span>{{ getIncomes.count }}</span>
    </div>
    <div class="flex items-end justify-center">
      <Button label="Agregar Ingreso"
              icon="pi pi-th-large"
              class="mr-2 p-button-info"
              @click="dialogAddIncome = !dialogAddIncome"
              v-if="$can('new-gas-and-oil.caja-menor.ver-caja-menor.agregar')"
      />
    </div>
  </div>

  <Dialog v-model:visible="dialogAddIncome" :style="{width: '450px'}" header="Agregar Ingreso" :modal="true"
          class="p-fluid">
    <div class="field mt-2">
      <label for="concept">Concepto</label>
      <InputText id="concept"
                 v-model.trim="income.concept"
                 required="true"
                 class="p-inputtext-sm p-mt-1"
      />
    </div>
    <div class="field mt-2">
      <label for="amount">Valor</label>
      <InputNumber id="amount" v-model="income.amount" mode="currency" currency="COP" locale="es-CO" :maxFractionDigits="0"
      />
    </div>
    <template #footer>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-danger"
        @click="dialogAddIncome = !dialogAddIncome"
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        class=""
        @click="saveIncome"
      />
    </template>
  </Dialog>
  <Toast/>
</template>

<script>
import storeIncomes from '../../../../stores/storeIncomes'
import storeHeader from '../../../../stores/storeHeader'
import { computed, ref, onMounted } from 'vue'
import Swal from 'sweetalert2'
import { useToast } from 'primevue/usetoast'
import dayjs from 'dayjs'

export default {
  name: 'homeTabsIngresosHeader',
  setup () {
    const toast = useToast()
    const paginator = computed(() => storeIncomes.getters.getPaginator)
    const numberDoc = ref(null)
    const dateSelected = ref(null)
    const storeTotalInc = computed(() => storeHeader.getters.getTotalIncomes)
    const getIncomes = computed(() => storeIncomes.getters.getIncomes)
    const dialogAddIncome = ref(false)
    const income = ref({
      concept: '',
      regimen: null,
      amount: 0
    })
    // const route = useRoute()

    const clearFilter = () => {
      if (dateSelected.value !== null) {
        numberDoc.value = null
        dateSelected.value = null
        storeIncomes.dispatch('searchIncomes', { limit: 8, offset: 0 })
        storeIncomes.dispatch('gettingPaginator', { rows: 8, first: 0 })
      }
    }

    const onChangeDate = () => {
      storeIncomes.dispatch('searchIncomes', {
        limit: 8,
        offset: 0,
        createdAt: dayjs(dateSelected.value).format('YYYY-MM-DD'),
        numberDoc: numberDoc.value
      })
      storeIncomes.dispatch('gettingPaginator', { rows: 8, first: 0 })
    }

    const onChangeNumberDoc = () => {
      storeIncomes.dispatch('searchIncomes', {
        limit: 8,
        offset: 0,
        createdAt: dateSelected.value,
        numberDoc: numberDoc.value
      })
      storeIncomes.dispatch('gettingPaginator', { rows: 8, first: 0 })
    }

    const saveIncome = () => {
      if (income.value.concept !== '' &&
        income.value.amount > 0
      ) {
        storeIncomes.dispatch('saveIncome', {
          concept: income.value.concept,
          amount: income.value.amount
        })
        storeIncomes.dispatch('searchIncomes', {
          limit: paginator.value.rows,
          offset: paginator.value.first,
          numberDoc: numberDoc.value
        })
        dialogAddIncome.value = false
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Se agrego el ingreso correctamente', life: 3000 })
        storeHeader.dispatch('gettingCashBalance')
        storeHeader.dispatch('gettingTotalIncomes')
        income.value.concept = ''
        income.value.amount = 0
      } else {
        Swal.fire(
          'Advertencia',
          'Todos los campos son obligatorios',
          'warning'
        )
      }
    }

    const onUpload = (event) => {
      income.value.document = event.target.files[0]
    }

    onMounted(() => {
      storeIncomes.dispatch('searchTypesIncomes')
      storeIncomes.dispatch('searchIncomes', { limit: 8, offset: 0 })
    })

    return {
      numberDoc,
      dateSelected,
      storeTotalInc,
      getIncomes,
      dialogAddIncome,
      income,
      clearFilter,
      onUpload,
      onChangeDate,
      onChangeNumberDoc,
      saveIncome
    }
  }
}
</script>

<style>
.swal2-container {
  z-index: 9999;
}
</style>
