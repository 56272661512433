<template>
  <DataTable :value='storeIncomesList.rows' responsiveLayout='scroll' class="p-datatable-sm mt-4">
    <template #empty>
      No hay datos para mostrar
    </template>
    <Column field='id' header='Id'></Column>
    <Column field='createdAt' header='Fecha'>
      <template #body="{data}">
        {{ $h.formatDate(data.createdAt) }}
      </template>
    </Column>
    <Column field='concept' header='Concepto' style="width: 40%"></Column>
    <Column field='amount' header='Valor'>
      <template #body="{data}">
        {{ $h.formatCurrency(parseFloat(data.amount), 2) }}
      </template>
    </Column>
    <Column header='Saldo Restante'>
      <template #body="{data}">
        {{ $h.formatCurrency(parseFloat(data.restante), 2) }}
      </template>
    </Column>
    <Column field='createdBy' header='Responsable'>
      <template #body="{data}">
        {{ data.full_name }}
      </template>
    </Column>
    <Column field='acciones' header='Acciones'>
      <template #body="{data}">
        <Button icon="pi pi-wallet"
                class="p-button-rounded mr-2"
                v-tooltip.top="{value: 'Ver gastos'}"
                @click="showExpenses(data.id)"
        />
        <template>
          <Button icon="pi pi-trash"
                  class="p-button-rounded mr-2 p-button-info"
                  v-tooltip.top="{value: 'Eliminar'}"
                  @click="deleteIncome(data.id)"
                  v-if="$can('new-gas-and-oil.caja-menor.ver-caja-menor.eliminar')"
          />
        </template>
      </template>
    </Column>
  </DataTable>
  <Paginator v-model:first="paginator.first" :rows="paginator.rows" :totalRecords="storeIncomesList.count" @page="onPage($event)"></Paginator>
</template>

<script>
import { computed } from 'vue'
import storeIncomes from '../../../../stores/storeIncomes'
import Swal from 'sweetalert2'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'
import storeHeader from '@/apps/newGasAndOil/newGasPettyCash/stores/storeHeader'

export default {
  name: 'homeTabsIngresosTable',
  setup () {
    const storeIncomesList = computed(() => storeIncomes.getters.getIncomes)
    const paginator = computed(() => storeIncomes.getters.getPaginator)
    const toast = useToast()
    const router = useRouter()

    const deleteIncome = (id) => {
      Swal.fire({
        title: 'Esta seguro/a?',
        text: 'Si se elimina el ingreso, se eliminaran los gastos asociados y no se podrá recuperar!',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminalo!'
      }).then((result) => {
        if (result.isConfirmed) {
          storeIncomes.dispatch('deleteIncome', id)
          storeIncomes.dispatch('searchIncomes', { limit: paginator.value.rows, offset: paginator.value.first })
          storeHeader.dispatch('gettingTotalIncomes')
          storeHeader.dispatch('gettingCashBalance')
          toast.add({ severity: 'success', summary: 'Exito', detail: 'Se elimino el ingreso correctamente', life: 3000 })
        } else {
          toast.add({ severity: 'info', summary: 'Informacion', detail: 'Se cancelo correctamente', life: 3000 })
        }
      })
    }

    const onPage = (event) => {
      storeIncomes.dispatch('gettingPaginator', paginator.value)
      storeIncomes.dispatch('searchIncomes', { limit: paginator.value.rows, offset: event.first })
    }

    const showExpenses = (id) => {
      router.push({ name: 'pettycash-newgas.expenses', params: { id } })
    }

    return {
      showExpenses,
      storeIncomesList,
      deleteIncome,
      onPage,
      paginator
    }
  }
}
</script>

<style scoped>

</style>
